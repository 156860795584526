<template>
  <v-row class="ma-1">
    <v-col cols="12" sm="4">
      <slot name="title">
        <h5 v-text="title" />
      </slot>
    </v-col>
    <v-col cols="12" sm="8">
      <slot>
        {{ value }}
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    default: "",
    value: {
      required: false,
    },
  },
};
</script>

<style></style>
